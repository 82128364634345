import { BackButton } from "@/components/Buttons/BackButton";
import { Center } from "@/components/Center";
import { Container } from "@/components/Container";
import { InfoIcon } from "@/components/Icons";
import { TonIcon } from "@/components/Icons";
import { Avatar } from "@/components/Icons/Avatar";
import Tabs from "@/components/Tabs";
import { TonUsdPill } from "@/components/TonUsdPill";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useTonUsdPriceContext } from "@/contexts/TonUsdContext";
import { useUserContext } from "@/contexts/UserContext";
import { LoadingSpinner } from "@roman/shared/components/LoadingSpinner";
import { useSchedule } from "@roman/shared/hooks/soccer/useSchedule";
import { useCountDown } from "@roman/shared/hooks/useCountDown";
import type { RomanApiError } from "@roman/shared/lib/net/romanApi";
import type { RoundDetails } from "@roman/shared/types/RoundDetails";
import type { RoundEntryUnit } from "@roman/shared/types/RoundEntryUnit";
import type { ScheduledEntry } from "@roman/shared/types/Schedule";
import { getErrorMessage } from "@roman/shared/utils/error";
import { formatNanoTon } from "@roman/shared/utils/formatNanoTon";
import { fromNano } from "@ton/ton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { RoundInfo } from "../Round/RoundInfo";
import { PlayerTable } from "./PlayerTable";
import { UserEntries } from "./UserEntries";

export const SchedulePage: React.FC = () => {
  const [selectedPlayersMap, setSelectedPlayersMap] = useState<Record<string, boolean>>({});
  const [selectedEntry, setSelectedEntry] = useState<ScheduledEntry | null>(null);
  const [isPlayerTab, setIsPlayerTab] = useState(true);

  const { roundEntryUnitId } = useParams<{ roundEntryUnitId: string }>();
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location]);
  const name = queryParams.get("matchName");

  const { openModal } = useErrorModalContext();
  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error: ", message);
    },
    [openModal],
  );

  if (!roundEntryUnitId) {
    return <div>Error: Invalid roundEntryUnitId</div>;
  }

  const user = useUserContext();
  const { roundEntryUnit, round, entries, isLoading, totalEntries } = useSchedule(
    roundEntryUnitId,
    user.id,
    handleError,
  );

  useEffect(() => {
    if (entries.length) {
      setSelectedEntry(entries[0]);
    }
  }, [entries]);

  const selectedPlayers = useMemo(() => {
    if (!round) {
      return [];
    }

    const result = [];
    for (const i in round.players.homePlayers) {
      const homePlayer = round.players.homePlayers[i];
      const awayPlayer = round.players.awayPlayers[i];
      const selectPlayer = selectedPlayersMap[homePlayer.id] ? homePlayer : awayPlayer;

      if (selectPlayer) {
        result.push(selectPlayer);
      }
    }

    return result;
  }, [selectedPlayersMap, round]);

  useEffect(() => {
    if (!selectedEntry) {
      return;
    }

    const newSelectedPlayersMap: Record<string, boolean> = {};
    for (const playerId of selectedEntry.selectedPlayers) {
      newSelectedPlayersMap[playerId] = true;
    }

    setSelectedPlayersMap(newSelectedPlayersMap);
  }, [selectedEntry]);

  if (isLoading || !roundEntryUnit || !round || !name) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  return (
    <>
      <RoundInformation name={name} round={round} roundEntryUnit={roundEntryUnit} totalEntries={totalEntries} />

      {isPlayerTab && selectedEntry && (
        <div className="w-full flex justify-center items-center py-3">
          <div className="flex text-icon items-center justify-center gap-3">
            <Avatar photoUrl={selectedEntry.userPhotoUrl} />

            <div>{selectedEntry.userName}</div>
          </div>
        </div>
      )}

      <div className="py-3">
        <Tabs defaultIndex={isPlayerTab ? 0 : 1}>
          {selectedEntry ? (
            <Tabs.Item title="Player" onClick={() => setIsPlayerTab(true)}>
              <PlayerTable
                selectedCaptains={selectedEntry.selectedCaptains}
                roundEntryUnitId={roundEntryUnitId}
                entryId={selectedEntry.id}
                roundId={round.id}
                selectedPlayers={selectedPlayers}
              />
            </Tabs.Item>
          ) : (
            <> </>
          )}

          <Tabs.Item title="User" onClick={() => setIsPlayerTab(false)}>
            <UserEntries
              round={round}
              entries={entries}
              onClick={(entry) => {
                setIsPlayerTab(true);
                setSelectedEntry(entry);
              }}
            />
          </Tabs.Item>
        </Tabs>
      </div>
    </>
  );
};

type RoundInformationProps = {
  name: string;
  round: RoundDetails;
  roundEntryUnit: RoundEntryUnit;
  totalEntries: number;
};

export const RoundInformation: React.FC<RoundInformationProps> = ({ name, round, roundEntryUnit, totalEntries }) => {
  const { deadlineTime } = useCountDown(new Date(round.entryCloseTime));
  const { tonUsdPrice } = useTonUsdPriceContext();
  const [isRoundInfoTabsOpen, setIsRoundInfoTabsOpen] = useState(false);

  return (
    <Container className="bg-gradient w-screen -mx-4">
      <div className="content">
        <div className="flex mb-2.5 px-2 flex-justify-between">
          <BackButton className="self-start" />
          <div className="flex flex-col w-full justify-center items-center">
            <div className="flex justify-center items-center w-full">
              <p className="text-icon pr-1.5">{name}</p>
              <InfoIcon onClick={() => setIsRoundInfoTabsOpen(true)} />
            </div>

            <p className="text-gradient">Deadline time: {deadlineTime}</p>
          </div>
        </div>

        <div className="flex space-x-2 text-icon justify-between">
          <div className="flex flex-col items-center w-1/4">
            <p className="text-xs">Entries</p>
            <p className="text-sm">
              {totalEntries}/{roundEntryUnit.maxNumEntries}
            </p>
          </div>

          <div className="flex flex-col items-center w-1/2">
            <p className="text-xs">Total Prizes</p>
            <div className="flex justify-center items-center gap-1">
              <TonIcon />
              <p className="text-sm">
                {formatNanoTon(roundEntryUnit.prizePool)}&nbsp;
                {tonUsdPrice !== undefined ? (
                  <span>(${(Number.parseFloat(fromNano(roundEntryUnit.prizePool)) * tonUsdPrice).toFixed(4)})</span>
                ) : (
                  <LoadingSpinner size="sm" />
                )}
              </p>
            </div>
          </div>

          <div className="w-1/4">
            <TonUsdPill className="h-[21px] text-[9px] w-11/12" />
          </div>
        </div>
      </div>
      {isRoundInfoTabsOpen && (
        <RoundInfo
          sportName={round.abbreviatedName ? round.abbreviatedName : round.sportEventName}
          roundId={round.id}
          sportEventStartTime={round.sportEventStartTime}
          onClose={() => setIsRoundInfoTabsOpen(false)}
        />
      )}
    </Container>
  );
};
