import { Center } from "@/components/Center";
import { TonIcon } from "@/components/Icons";
import Tabs from "@/components/Tabs";
import { TonUsdPill } from "@/components/TonUsdPill";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useTonUsdPriceContext } from "@/contexts/TonUsdContext";
import { LoadingSpinner } from "@roman/shared/components/LoadingSpinner";
import { useSoccerEntryPrize } from "@roman/shared/hooks/soccer/useSoccerEntryPrize";
import { useSoccerRound } from "@roman/shared/hooks/soccer/useSoccerRound";
import type { RomanApiError } from "@roman/shared/lib/net/romanApi";
import type { PrizeAmount } from "@roman/shared/types/RoundEntryPrize";
import type { RoundEntryUnit } from "@roman/shared/types/RoundEntryUnit";
import { getErrorMessage } from "@roman/shared/utils/error";
import { formatNanoTon } from "@roman/shared/utils/formatNanoTon";
import { fromNano } from "@ton/ton";
import ordinal from "ordinal";
import { useCallback } from "react";

export const Prize = ({ roundId }: { roundId: string }) => {
  const { openModal } = useErrorModalContext();
  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error fetching prizes", message);
    },
    [openModal],
  );
  const handleRoundError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error getting prizes", message);
    },
    [openModal],
  );
  const { data: prizeData, isLoading: isPrizeDataLoading } = useSoccerEntryPrize(roundId, handleError);
  const { data: roundData } = useSoccerRound(roundId, handleRoundError);

  if (isPrizeDataLoading || !roundData) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  if (prizeData.roundEntryPrize.length === 0) {
    return (
      <div className="flex justify-center items-center h-52">
        <p className="text-white">no data to show</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full py-4">
      <div className="flex justify-end mb-8">
        <TonUsdPill />
      </div>

      <Tabs>
        {prizeData.roundEntryPrize.map((entry) => {
          const roundEntry = roundData.round.roundEntryUnits.find(
            (round) => round.id === entry.roundEntryUnitId,
          ) as RoundEntryUnit;

          return (
            <Tabs.Item
              title={entry.entryFee === "0" ? "Free" : `${formatNanoTon(entry.entryFee)} TON`}
              key={entry.roundEntryUnitId}
            >
              <PrizeContent
                prizes={entry.prize}
                totalPayOut={entry.totalPayOut}
                maxEntries={roundEntry.maxNumEntries}
                numEntries={roundEntry.numEntries}
                maxNumEntriesPerUser={roundEntry.maxNumEntriesPerUser}
              />
            </Tabs.Item>
          );
        })}
      </Tabs>
    </div>
  );
};

export const transformPrizeAmounts = (prizeAmounts: PrizeAmount[]) => {
  return prizeAmounts.flatMap(({ startRank, endRank, amount }) => {
    // For ranks 1-3, we need to expand them individually
    if (startRank <= 3) {
      const prizes = [];
      for (let rank = startRank; rank <= Math.min(endRank, 3); rank++) {
        prizes.push({
          rank: ordinal(rank),
          amount,
        });
      }

      return prizes;
    }

    // For ranks after 3, use ranges
    return [
      {
        rank: startRank === endRank ? ordinal(startRank) : `${ordinal(startRank)}-${ordinal(endRank)}`,
        amount,
      },
    ];
  });
};

const PrizeContent = ({
  prizes,
  totalPayOut,
  numEntries,
  maxEntries,
  maxNumEntriesPerUser,
}: {
  prizes: PrizeAmount[];
  totalPayOut: string;
  maxEntries: number;
  numEntries: number;
  maxNumEntriesPerUser: number | null;
}) => {
  const transformedPrizes = transformPrizeAmounts(prizes);
  const { tonUsdPrice } = useTonUsdPriceContext();

  const totalUsdPrize =
    tonUsdPrice !== undefined ? `$${(Number(fromNano(totalPayOut)) * tonUsdPrice).toFixed(2)}` : undefined;

  return (
    <div>
      <div className="flex justify-between items-center py-4">
        <p className="textsm text-white">Prize Payouts</p>
        <div className="flex gap-1">
          <TonIcon />
          <div className="flex flex-col items-end">
            <p className="text-white text-[22px]">{fromNano(totalPayOut)}</p>
            {totalUsdPrize ? <p className="text-icon text-xs">{totalUsdPrize}</p> : <LoadingSpinner size="sm" />}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center pb-4">
        <p className="text-xs text-white">Entries</p>
        <p className="text-icon text-xs">
          {numEntries}/{maxEntries}
        </p>
      </div>

      {maxNumEntriesPerUser !== null && (
        <div className="flex justify-between items-center pb-4">
          <p className="text-xs text-white">Entry Limits</p>
          <p className="text-xs text-icon">Multi-Entry({maxNumEntriesPerUser})</p>
        </div>
      )}

      {/* table */}
      <div className="flex flex-col p-4 bg-button-background rounded-md">
        <div className="flex flex-col gap-3">
          {transformedPrizes.slice(0, 3).map((prize) => (
            <RankRow className="text-white text-sm" {...prize} key={prize.rank} />
          ))}
        </div>
        <div className="w-full border border-solid my-4" />
        <div className="flex flex-col gap-3">
          {transformedPrizes.slice(3).map((prize) => (
            <RankRow className="text-icon text-xs" {...prize} key={prize.rank} />
          ))}
        </div>
      </div>
    </div>
  );
};

const RankRow = ({ rank, amount, className }: { rank: string; amount: string; className?: string }) => {
  return (
    <div className={`flex justify-between ${className}`}>
      <p>{rank}</p>
      <div className="flex gap-1 justify-center items-center">
        <TonIcon />
        <p>{fromNano(amount)}</p>
      </div>
    </div>
  );
};
